* { box-sizing: border-box; }

:root {
  /* --h: 50vmin; */
  --h: min(50vh, 28vw);
  --w: 50vmin;
  --h2: calc(var(--h) / 2);
  --w2: calc(var(--w) / 2);
}

body { font-family: sans-serif; }

.scene {
  width: var(--h);
  height: var(--h);
  perspective: 250vmin;
  left: calc(50% - var(--h2));
  position: relative;
  top: calc(50% - var(--h2));
  cursor: grab;
}

.rotating {
  height: 100%;
  width: 100%;
}

.cube__face {
  position: absolute;
  width: var(--h);
  height: var(--h);
  border: 2px solid #fff;
  font-weight: bold;
  color: black;
  float: left;
  text-align: center;
}@media (max-width: 699px) {
  .cube__face {
    border: 0px;
  } }


.cube__face--front  { backface-visibility: hidden; }
.cube__face--right  { backface-visibility: hidden; }
.cube__face--back   { backface-visibility: hidden; }
.cube__face--left   { backface-visibility: hidden; }
.cube__face--top    { backface-visibility: hidden; }
.cube__face--bottom { backface-visibility: hidden; }

.cube__face--front  { background: var(--bckg-color); }
.cube__face--right  { background: var(--bckg-color); }
.cube__face--back   { background: var(--bckg-color); }
.cube__face--left   { background: var(--bckg-color); }
.cube__face--top    { background: var(--bckg-color); }
.cube__face--bottom { background: var(--bckg-color); }

.cube__face--front  { transform: rotateY( 0deg) translateZ(var(--h2)); }
.cube__face--right  { transform: rotateY( 90deg) translateZ(var(--h2)); }
.cube__face--back   { transform: rotateY(180deg) translateZ(var(--h2)); }
.cube__face--left   { transform: rotateY(-90deg) translateZ(var(--h2)); }
.cube__face--top    { transform: rotateX( 90deg) translateZ(var(--h2)); }
.cube__face--bottom { transform: rotateX(-90deg) translateZ(var(--h2)); }

@-webkit-keyframes rotatingAnimationY {
    /* 0%{
      -webkit-transform: rotateX(0deg) rotateY(0deg);
              transform: rotateX(0deg) rotateY(0deg);
    }
    100% {
      -webkit-transform: rotateX(360deg) rotateY(360deg);
              transform: rotateX(360deg) rotateY(360deg);
    } */
    0%{
      -webkit-transform: rotateX(0deg) rotateY(0deg);
              transform: rotateX(0deg) rotateY(0deg);
    }
    25%{
      -webkit-transform: rotateX(60deg) rotateY(90deg);
              transform: rotateX(60deg) rotateY(90deg);
    }
    50%{
      -webkit-transform: rotateX(0deg) rotateY(180deg);
              transform: rotateX(0deg) rotateY(180deg);
    }
    75%{
      -webkit-transform: rotateX(-60deg) rotateY(270deg);
      transform: rotateX(-60deg) rotateY(270deg);
    }
    100% {
      -webkit-transform: rotateX(0deg) rotateY(360deg);
              transform: rotateX(0deg) rotateY(360deg);
    }
  }
  @keyframes rotatingAnimationY {
    0%{
      -webkit-transform: rotateX(0deg) rotateY(0deg);
              transform: rotateX(0deg) rotateY(0deg);
    }
    25%{
      -webkit-transform: rotateX(60deg) rotateY(90deg);
              transform: rotateX(60deg) rotateY(90deg);
    }
    50%{
      -webkit-transform: rotateX(0deg) rotateY(180deg);
              transform: rotateX(0deg) rotateY(180deg);
    }
    75%{
      -webkit-transform: rotateX(-60deg) rotateY(270deg);
      transform: rotateX(-60deg) rotateY(270deg);
    }
    100% {
      -webkit-transform: rotateX(0deg) rotateY(360deg);
              transform: rotateX(0deg) rotateY(360deg);
    }
  }

  @media (max-width: 699px) {
    :root {
      --h: 52vmin;
    }
    .scene{
        position: fixed;
        margin-top: 20px;
    }
  }
