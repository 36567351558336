body {
  margin: 0;
  font-family: 'Neue Helvetica', Helvetica, Arial, sans-serif;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: "LitteraPlain";
  font-style: normal;
  src: local("LitterPlain"), url(./assets/fonts/LitteraPlainBold.otf) format('opentype');
}
@font-face {
  font-family: "LitteraPlain";
  font-style: bold;
  font-weight: bold;
  src: local("LitteraPlain"), url(./assets/fonts/LitteraPlainBook.otf) format('opentype');
}
@font-face {
  font-family: 'Pitcrew';
  font-weight: 400;
  font-style: normal;
  src: url(./assets/fonts/Pitcrew.woff2) format("woff2"), url(./assets/fonts/Pitcrew.woff) format("woff");
}
