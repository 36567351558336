.info-container {
  position: fixed;
  margin-top: 40px;
  height: 100%;
  left: 50%;
  transform: translate(-50%);
}

.bckg__click {
  position: fixed;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.bckg-slide-down {
  top: 110%
}

.bckg-slide-up {
  top: 0;
}

.slide-up, .slide-down {
  /* overflow:hidden; */
  border: none;
}

.slide-down {
  position: absolute;
  transform: translateY(500%);
  transition: transform 0.6s cubic-bezier(0.64, 0, 0.78, 0);
  -webkit-transition: transform 0.6s cubic-bezier(0.64, 0, 0.78, 0);
  -webkit-transform: translateY(500%);
}

.slide-up {
  position: sticky;
  display: block;
  transition: transform 0.6s cubic-bezier(0.22, 1, 0.36, 1);
  -webkit-transition: transform 0.6s cubic-bezier(0.22, 1, 0.36, 1);
}

.info-page {
  width: 80vh;
  background: var(--bckg-color);
  opacity: 1;
  overflow-y:scroll;
  overflow-x:hidden;
  visibility: visible;
}

.info-page.off {
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
}


/*
CSS
*/
.house__container {
top: 100%;
position: fixed;
}
.link {
  text-decoration: none;
  color: #FFFFFF;
  transition: 200ms; }
  .link:visited {
    opacity: .16; }
  .link:hover {
    color: #000000; }

.link__nav {
  font-size: 20px;
  line-height: 1.05;
  letter-spacing: 0.2px;
  border-bottom: none;
  color: #000000; }

.house {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  overflow: auto;
  max-width: 960px;
  margin: auto;
  margin-top: 40px;
  background-color: #FFE03B;
  z-index: 2;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  -moz-column-gap: 24px;
       column-gap: 24px; }
  @media (max-width: 699px) {
    .house {
      display: block;
      margin-top: 0;
      padding-bottom: 84px; } }

.house::-webkit-scrollbar {
  display: none;
}

.house__header {
  grid-column: 1 / 9; }

.house__about {
  grid-column: 1 / 9;
  display: flex;
  justify-content: space-between;
  padding: 0px 0px 68px 40px; }
  @media (max-width: 699px) {
    .house__about {
      padding: 0px 20px 40px 20px; } }

.house__h1 {
  font-family: 'Pitcrew', sans-serif;
  font-size: 84px;
  line-height: 1;
  font-weight: 400;
  margin: 0;
  padding: 0;
  padding: 60px 0px 32px 40px;
  font-feature-settings: "salt"; }
  @media (max-width: 699px) {
    .house__h1 {
      font-size: 33px;
      line-height: 1.05;
      padding: 40px 20px 16px 20px; } }
  @media (max-width: 321px) {
    .house__h1 {
      font-size: 29px; } }

.house__lead {
  grid-column: 1 / 9;
  font-size: 38px;
  line-height: 1.1;
  letter-spacing: -0.25px;
  margin: 0;
  padding: 0px 0px 40px 40px; }
  @media (max-width: 699px) {
    .house__lead {
      font-size: 21px;
      line-height: 1.25;
      padding: 0px 20px 28px 20px; } }
  @media (max-width: 321px) {
    .house__lead {
      font-size: 19px; } }

.house__description {
  font-size: 24px;
  line-height: 1;
  letter-spacing: 0.35px;
  margin: 0;
  padding: 0; }
  @media (max-width: 699px) {
    .house__description {
      font-size: 14px;
      letter-spacing: 0.15px; } }

.house__address {
  grid-column: 1 / 9;
  padding: 0px 0px 72px 40px; }
  @media (max-width: 699px) {
    .house__address {
      padding: 0px 20px 32px 20px; } }

.house__text {
  margin: 0;
  margin-bottom: 16px;
  font-size: 17px;
  line-height: 1.3;
  letter-spacing: 0.15px; }
  @media (max-width: 699px) {
    .house__text {
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 1.4;
      letter-spacing: 0.2px; } }

.house__text--fsb {
  position: relative;
}

.house__text--fsb::after {
  content: "Мы не смогли сфотографировать это здание, потому что оно находится в собственности ***. Но это не мешает вам увидеть его своими глазами!";
  position: absolute;
  bottom: 320px;
  left: 0px;

  color: white;
  width: 82%;
  font-size: 24px;
  line-height: 1.2;
}
@media (max-width: 699px) {
  .house__text--fsb::after {
    width: 82%;
    bottom: 130px;
    font-size: 14px;
  }
}

.house__text--no-margin {
  margin: 0; }

.house__img {
  margin-bottom: 88px; }
  @media (max-width: 699px) {
    .house__img {
      padding-right: 20px;
      margin-bottom: 64px; } }

.house__img--margin-16 {
  margin-bottom: 16px; }
  @media (max-width: 699px) {
    .house__img--margin-16 {
      margin-bottom: 8px; } }

.house__img--margin-40 {
  margin-bottom: -40px; }
  @media (max-width: 699px) {
    .house__img--margin-40 {
      margin-bottom: -20px; } }

.house__img--padding-left {
  padding: 0; }
  @media (max-width: 699px) {
    .house__img--padding-left {
      padding-left: 20px; } }

.house__img--1-9 {
  grid-column: 1 / 9; }
  @media (max-width: 699px) {
    .house__img--1-9 {
      grid-column: 1 / 5; } }

.house__img--2-10 {
  grid-column: 2 / 10; }
  @media (max-width: 699px) {
    .house__img--2-10 {
      grid-column: 2 / 6; } }

.house__left {
  grid-column: 1 / 5;
  padding: 0px 0px 72px 40px; }
  @media (max-width: 699px) {
    .house__left {
      padding: 0px 20px 0px 20px; } }

.house__right {
  grid-column: 5 / 9;
  padding: 0px 0px 72px 40px; }
  @media (max-width: 699px) {
    .house__right {
      padding: 0px 20px 32px 20px; } }

.house__footer {
  grid-column: 1 / 10;
  margin-bottom: 72px; }

  @media (max-width: 699px) {
    .house__footer {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;
      margin-right: 25px;
      margin-left: 20px;
      position: fixed;
      width: 100%;
      margin: 0 auto;
      bottom: 30px;
      padding: 0 20px;
      padding-right: 25px; } }

.house__footer--home {
  display: none;
  z-index: 10;
}

@media (max-width: 699px) {
  .house__footer--home {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    margin-right: 25px;
    margin-left: 20px;
    /* position: absolute; */
    position: fixed;
    bottom: 30px;
    width: 100%;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 25px; } }

    @media (max-width: 321px) {
      .house__footer--home {
        justify-content: space-evenly;
        padding: 0; } }

.house__button {
  display: block;
  position: relative;
  margin: 0 auto;
  padding: 26px 40px 22px 40px;
  width: 380px;
  font-size: 22px;
  line-height: 1;
  letter-spacing: 0.5px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: #FFFFFF;
  border: 5px solid #000000;
  transition: background-color 0.3s ease;
}
  @media (max-width: 699px) {
    .house__button {
      font-size: 20px;
      margin: 0;
      padding: 24px 40px 20px 40px;
      width: 216px; } }
  .house__button:hover {
    background-color: #FFE03B;
    transition: background-color 0.3s ease; }

.house__button--close {
  display: none; }
  @media (max-width: 699px) {
    .house__button--close {
      display: block;
      width: 74px;
      height: 74px;
      padding: 0;
      background: url(./images/close.svg) no-repeat center;
      background-color: #FFFFFF; }
    .house__button:hover {
        background-color: #fff; }}

.house__button--info  {
  display: none; }
  @media (max-width: 699px) {
    .house__button--info {
      display: block;
      width: 74px;
      height: 74px;
      padding: 0;
      background: url(./images/about.svg) no-repeat center;
      background-color: #FFFFFF; }
    }


.house__button::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 0px;
  width: 375px;
  height: 75px;
  border-style: solid solid none none;
  border-color: #000;
  border-width: 5px; }
  @media (max-width: 699px) {
    .house__button::before {
      width: 211px;
      height: 69px; } }

@media (max-width: 699px) {
  .house__button--close::before {
    width: 69px;
    height: 69px; }
    .house__button--info::before {
      width: 69px;
      height: 69px; } }


body {
  font-family: 'Neue Helvetica', Helvetica, Arial, sans-serif;
  font-weight: 400;
  margin: 0;
  padding: 0;
  background: #FFFFFF;
  /* transition: all ease 300ms;  */
}

img {
  max-width: 100%;
  height: auto; }
