:root {
    --bckg-color: #FFE03B;
    --box-size: 80px;
    --box-margin: 40px;
    --marker-size: 48px;
}

*{
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

img {
    display: block;
}

.container {
    width: 100vw;
    height: 100vh;
    min-height: -webkit-fill-available;
    height: calc(var(--vh, 1vh) * 100);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.about-content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background: var(--bckg-color);
}

.heading {
    width: 65%;
}@media (max-width: 699px) {
    .heading {
        width: 82%;
        margin-bottom: 32px;
    } }

.header {
    position: absolute;
    left: var(--box-margin);
    top: var(--box-margin);
}@media (max-width: 699px) {
    .header {
        position: fixed;
      width: 100%;
      left: 0px;
      top: 0px;
      background-color: var(--bckg-color);
      padding: 20px;
      padding-bottom: 28px;
      /* z-index: 100; */
    } }

    @media (max-width: 699px) {
        .header-overlay {
            z-index: 100;
        }
    }

.logo-museum {
    width: 100%;
    opacity: 1;
}@media (max-width: 699px) {
    @keyframes dissapear {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
            /* transform: scale(.5); */
        }
    }
    .logo-museum {
        -webkit-animation: dissapear 1s ease 0s forwards;
        animation: dissapear 1s ease 0s forwards;
        animation-delay: 1.65s;
    } }

.about {
    bottom: var(--box-margin);
    left: var(--box-margin);
    height: var(--box-size);
    width: var(--box-size);
}@media (max-width: 699px) {
    .about {
        left: 20px;
    } }

button {
    outline: none;
    cursor: pointer;
    background-color: #fff;
} button:hover {
    background-color: #FFE03B;
    transition: background-color 0.3s ease; }

.button-link {
    outline: none;
    cursor: pointer;
    background-color: #fff;
} .button-link:hover {
    background-color: #FFE03B;
    transition: background-color 0.3s ease; }

.box {
    position: fixed;
    border: 5px solid #000;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 22px;
    line-height: 1;
    letter-spacing: 0.5px;
    font-weight: bold;
    z-index: 1;
    text-transform: uppercase;
    background-color: #FFFFFF;
    border: 5px solid #000000;
}@media (max-width: 699px) {
    .box {
      display: none;
    } }

.about-img {
    /* margin: auto;
    vertical-align: middle; */
        width: 75%;
        margin: 0 auto;
}@media (max-width: 699px) {
    .about-img {
        width: 75%;
        margin: 0 auto;
    } }

.box::before {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
    position: absolute;
    content: "";
    height: inherit;
    width: inherit;
    border-width: 5px;
    border-color: #000;
    border-style: solid solid none none;
    bottom: 0px;
    left: 0px;
}

.show-box {
    display: block;
}

.show-button {
    display: block;
}@media (max-width: 699px) {
    .show-button {
        display: none;
    } }

.map-switcher {
    display: block;
    bottom: var(--box-margin);
    background: #fff;
    font-size: 22px;
    line-height: 1;
    letter-spacing: 0.5px;
    width: 280px;
    text-transform: uppercase;
    height: var(--box-size);
    margin: 0 auto;
    padding: 26px 40px 22px 40px;
    left: 0;
    right: 0;
    text-align: center;
}@media (max-width: 699px) {
    .map-switcher {
      display: none;
      font-size: 20px;
      margin: 0;
      width: 230px;
      margin-bottom: 0;
      margin-left: 25%;
    } }

.link-switcher {
    display: block;
    bottom: var(--box-margin);
    position: absolute;
    background: #fff;
    font-size: 22px;
    line-height: 1;
    letter-spacing: 0.5px;
    width: 280px;
    text-transform: uppercase;
    height: var(--box-size);
    margin: 0 auto;
    padding: 26px 40px 22px 40px;
    left: 0;
    right: 0;
    text-align: center;
    }@media (max-width: 699px) {
        .link-switcher {
            display: none;
            font-size: 20px;
            margin: 0;
            width: 230px;
            margin-bottom: 0;
            margin-left: 25%;
        } }

.marker {
    background-size: cover;
    width: var(--marker-size);
    height: var(--marker-size);
    left: calc(50% - var(--marker-size) / 2);
    top: calc(50% - var(--marker-size) / 2);
    cursor: pointer;
    background-color: var(--bckg-color);
}

@media (max-width: 699px) {
    .home__logo {
        width: 104px;
    }
}

@media (max-width: 699px) {
    :root {
        --box-size: 74px;
        --box-margin: 20px;
        --marker-size: 36px;
    }

    .container {
        display: inline-block;
        /* overflow: hidden; */
        /* position:fixed; */
        touch-action: none;
    }

    .about-content {
        height: 100%;
        /* position: fixed; */
        touch-action: none;
    }

    .cube-content {
        /* position: fixed; */
    }

    .rotating {
        position: relative;
        -webkit-animation: move-up 3s cubic-bezier(0.65, 0, 0.35, 1) 0s forwards;
        animation: move-up 3s cubic-bezier(0.65, 0, 0.35, 1) 0s forwards;
    }

    @keyframes move-up {
        0% {
            -webkit-transform: translateY(-150vh);
            transform: translateY(-150vh);
        }
        /* 40% {
            -webkit-transform: translateY(-150vh);
            transform: translateY(-150vh);
        } */
        100% {
            -webkit-transform: translateY(-52vh);
            transform: translateY(-52vh);
        }
    }

    @-webkit-keyframes move-up {
        0% {
            -webkit-transform: translateY(-150vh);
            transform: translateY(-150vh);
        }
        30% {
            -webkit-transform: translateY(-150vh);
            transform: translateY(-150vh);
        }
        100% {
            -webkit-transform: translateY(-50vh);
            transform: translateY(-50vh);
        }
    }
  }
