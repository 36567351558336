.slide-up, .slide-down {
  /* overflow:hidden; */
  border: none;
}

.info-slide-down {
  position: fixed;
  top: 140px;
  transform: translateY(100%);
  transition: transform 0.6s cubic-bezier(0.64, 0, 0.78, 0);
  -webkit-transition: transform 0.6s cubic-bezier(0.64, 0, 0.78, 0);
  /* transform: translateY(100%);
  transition: transform 2s ease-in-out;
  -webkit-transform: translateY(100%);
  -webkit-transition: transform 2s ease-in-out; */
}

@media (max-width: 699px) {
  .info-slide-down {
    top: 90px; } }

.info-slide-up {
  position: fixed;
  top: 140px;
  bottom: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  transition: transform 0.6s cubic-bezier(0.22, 1, 0.36, 1);
  -webkit-transition: transform 0.6s cubic-bezier(0.22, 1, 0.36, 1);
}

@media (max-width: 699px) {
  .info-slide-up {
    top: 90px; } }

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.info {
    background-color: #FFE03B;
    width: 50%;
    padding: 0 40px 180px 40px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    z-index: 1;
    /* overflow: hidden; */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
 }
    @media (max-width: 699px) {
      .info {
        width: 100%;
        z-index: 5;
        display: block;
        padding: 0 20px 120px 20px;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch; } }

  .info__year {
    grid-column: 1 / 3;
    margin: 0;
    font-family: 'Pitcrew', sans-serif;
    font-size: 108px;
    line-height: 1;
    font-weight: 400; }
    @media (max-width: 699px) {
      .info__year {
        font-size: 80px;
        line-height: 1.05; } }

  .info__lead {
    grid-column: 1 / 7;
    margin: 0;
    font-size: 30px;
    line-height: 1.1;
    margin-top: 40px;
    margin-bottom: 44px; }
    @media (max-width: 699px) {
      .info__lead {
        font-size: 21px;
        line-height: 1.25;
        margin-top: 20px;
        margin-bottom: 24px; } }

  .info__text {
    grid-column: 3 / 7;
    margin: 0;
    margin-bottom: 16px;
    font-size: 17px;
    line-height: 1.3;
    letter-spacing: 0.15px; }
    @media (max-width: 699px) {
      .info__text {
        margin-bottom: 8px;
        font-size: 14px;
        letter-spacing: 0.15px; } }

  .info__team {
    grid-column: 3 / 5; }

  .info__name {
    margin: 0;
    font-size: 17px;
    line-height: 1.3;
    letter-spacing: 0.15px; }
    @media (max-width: 699px) {
      .info__name {
        font-size: 14px;
        letter-spacing: 0.15px; } }

  .info__name--title {
    opacity: 0.5; }

  .info__logos {
    grid-column: 3 / 5; }
    @media (max-width: 699px) {
      .info__logos {
        display: flex;
        flex-direction: column;
        align-items: flex-start; } }

  .info__text--margin-s {
    margin-bottom: 24px; }
    @media (max-width: 699px) {
      .info__text--margin-s {
        margin-bottom: 16px; } }

  .info__text--margin-m {
    margin-bottom: 40px; }
    @media (max-width: 699px) {
      .info__text--margin-m {
        margin-bottom: 32px; } }

  .info__text--margin-l {
    margin-bottom: 80px; }
    @media (max-width: 699px) {
      .info__text--margin-l {
        margin-bottom: 72px; } }
