:root {
  --box-size: 80px;
  --box-margin: 40px;
}

.map-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.mapboxgl-ctrl-zoom-in > .mapboxgl-ctrl-icon {
  background-image: url(./images/zoom-in.svg) !important;
  background-size: contain !important;
  -webkit-background-size: contain;
  position: fixed !important;
  height: 27% !important;
}

.mapboxgl-ctrl-zoom-out > .mapboxgl-ctrl-icon {
  background-image: url(./images/zoom-out.svg) !important;
  background-size: contain !important;
  -webkit-background-size: contain;
  position: fixed !important;
  height: 27% !important;
}

.mapboxgl-ctrl-group {
  background: none;
}

.mapboxgl-ctrl-group:not(:empty) {
  box-shadow: none;
}

.mapboxgl-ctrl button:not(:disabled):hover {
  background-color: none;
}

.mapboxgl-ctrl-group button {
  border: 5px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: var(--box-size);
  width: var(--box-size);
  background-color: #fff;
}

.mapboxgl-ctrl-zoom-in {
  border: 5px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: var(--box-size);
  width: var(--box-size);
  background-color: #fff;
} .mapboxgl-ctrl-zoom-in:hover {
  background-color: #FFE03B !important;
  transition: background-color 0.3s ease; }

  .mapboxgl-ctrl-zoom-out:hover {
    background-color: #FFE03B !important;
    transition: background-color 0.3s ease; }

    .mapboxgl-ctrl-geolocate:hover {
      background-color: #FFE03B !important;
      transition: background-color 0.3s ease; }

.mapboxgl-ctrl-geolocate::before, .mapboxgl-ctrl-zoom-in::before, .mapboxgl-ctrl-zoom-out::before
{
	padding: 0;
	margin: 0;
	box-sizing: border-box;
  position: absolute;
  content: "";
  height: inherit;
  width: inherit;
  border-width: 5px;
  border-color: #000;
  border-style: solid solid none none;
  bottom: 5px;
  left: 5px;
  z-index: -1;
}

.mapboxgl-ctrl-zoom-in::before {
  top: -5px;
}

.mapboxgl-ctrl button:not(:disabled):hover {
  background-color: #fff
};

.mapboxgl-ctrl-group button:focus {
  box-shadow: none;
}

.mapboxgl-ctrl-compass {
  display: none !important;
}

.mapboxgl-ctrl-top-right .mapboxgl-ctrl {
    margin: var(--box-margin) var(--box-margin) 0 0;
    float: right;
}

.mapboxgl-ctrl-zoom-in {
  margin-bottom: 20px;
}

.mapboxgl-ctrl-group button+button {
  border: 5px solid #000;
}

.mapboxgl-ctrl-geolocate {
  margin: 0 calc(var(--box-margin) - 10px) 10px 0;
}

.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl {
  margin: none none none none !important;
}

@media (max-width: 699px) {
  .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl {
    margin: 0 5px 0 0;
  } }

.mapboxgl-ctrl-geolocate::before
{
  bottom: 15px;
}

.mapboxgl-user-location-dot, .mapboxgl-user-location-dot::before {
  background-color: #000;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.mapboxgl-ctrl-bottom-right>.mapboxgl-ctrl-attrib.mapboxgl-compact:after {
  display: none;
}

.mapboxgl-user-location-accuracy-circle {
  background-color: rgba(0, 0, 0, 0.2);
  width: 1px;
  height: 1px;
  border-radius: 100%;
}

.mapboxgl-ctrl-geolocate > .mapboxgl-ctrl-icon {
  background-image: url(./images/geo.svg) !important;
  background-size: contain !important;
  -webkit-background-size: contain;
  position: fixed !important;
  height: 58% !important;
}

@media (max-width: 699px) {
  :root {
    --box-size: 74px;
    --box-margin: 30px;
  }
  .mapboxgl-ctrl-top-right {
    display: none;
  }
  .mapboxgl-ctrl-geolocate > .mapboxgl-ctrl-icon {
    background-image: url(./images/geo-m.svg) !important;
    background-size: contain !important;
    -webkit-background-size: contain;

    position: fixed !important;
    /* top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 75% !important; */

    /* background-size: contain; */
    height: 58% !important;
  }
}
/* @media (max-width: 699px) {
  .mapboxgl-ctrl-geolocate > .mapboxgl-ctrl-icon {
    background: url(./images/geo-m.svg) contain center !important;
    background-size: contain !important;
    height: 75% !important;
    -o-background-size:contain !important;
    -moz-background-size:contain !important;
    -webkit-background-size:contain !important;
    background-size: 100% 100% !important;
    background-repeat:no-repeat !important;
   } } */
